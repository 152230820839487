<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-text-field
          v-model="options.search"
          rounded
          dense
          outlined
          hide-details
          label="搜索"
          placeholder="按供应商名称、用户名、手机号搜索"
          :append-icon="icons.mdiMagnify"
          class="flex-grow-0"
          @click:append="getDesserts"
        />
        <v-spacer></v-spacer>
        <v-btn
          v-permission="['100001']"
          color="primary"
          dark
          @click="newItemOpenDialog"
        >
          添加供应商
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page.sync="options.pageSize"
        :page.sync="options.page"
        :server-items-length="totalDesserts"
        :loading="loading"
        :disable-sort="true"
        :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
        @update:items-per-page="getDesserts"
        @update:page="getDesserts"
      >
        <template v-slot:item.name="{ item }">
          <router-link :to="{ name: 'supplierDetail', params: { id: item.id } }">{{ item.name }}</router-link>
        </template>
        <template v-slot:item.is_active="{ item }">
          <v-icon
            :color="item.is_active ? 'success' : 'error'"
            dark
          >
            {{ item.is_active ? icons.mdiCheckCircle : icons.mdiCloseCircle }}
          </v-icon>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at | dateFormat }}
        </template>
        <template
          v-slot:item.actions="{ item }"
        >
          <v-btn
            v-permission="['100002']"
            icon
            @click="detailBtnClick(item)"
          >
            <v-icon>{{ icons.mdiArrowRightCircle }}</v-icon>
          </v-btn>
          <v-btn
            v-permission="['100004']"
            icon
            @click="deleteBtnClick(item)"
          >
            <v-icon>{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="createDialog"
      max-width="750"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card
        class="pa-sm-10"
      >
        <v-card-title class="justify-center text-h5">
          新增供应商
        </v-card-title>
        <v-card-text class="text-center mt-n2">
          创建新的供应商
        </v-card-text>
        <v-card-text class="mt-5">
          <v-form
            ref="supplierFormRef"
          >
            <v-text-field
              v-model="row.name"
              label="供应商名"
              :rules="[rules.required, rules.counter]"
              validate-on-blur
              counter="20"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="row.username"
              :counter="20"
              :rules="[rules.required, rules.counter]"
              label="联系人"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="row.mobile"
              :counter="11"
              :rules="[rules.required, rules.mobile]"
              label="手机号"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="row.password"
              type="password"
              label="登录密码"
              :rules="[rules.required, rules.password]"
              required
              outlined
              dense
            ></v-text-field>
            <v-select
              v-model="row.is_active"
              :items="supplierStatus"
              :rules="[rules.required]"
              required
              outlined
              dense
              label="状态"
            ></v-select>
            <v-select
              v-model="row.province"
              item-text="name"
              item-value="code"
              :items="provincesList"
              :loading="provinceLoading"
              :rules="[rules.required]"
              :no-data-text="provinceLoading ? '加载中......' : '暂无数据'"
              label="省/直辖市"
              outlined
              dense
              @click="getProvinces"
              @change="changeProvinces"
            ></v-select>
            <v-select
              v-model="row.city"
              item-text="name"
              item-value="code"
              :items="citiesList"
              :loading="cityLoading"
              :rules="[rules.required]"
              :no-data-text="!row.province ? '请先选择省份' : cityLoading ? '加载中......' : '暂无数据'"
              label="城市"
              outlined
              dense
              @click="getCities"
            ></v-select>
            <v-select
              v-model="row.county"
              item-text="name"
              item-value="code"
              :items="areasList"
              :loading="areaLoading"
              :rules="[rules.required]"
              :no-data-text="!row.city ? '请先选择城市' : areaLoading ? '加载中......' : '暂无数据'"
              label="区/县"
              outlined
              dense
              @click="getAreas"
            ></v-select>
            <v-text-field
              v-model="row.address"
              label="详细地址"
              :rules="[rules.required]"
              outlined
              dense
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center mt-3">
          <v-btn
            color="success"
            dark
            @click="createDialogSubmit"
          >
            保存
          </v-btn>
          <v-btn
            outlined
            @click="cancelDialog"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          确定要删除这条数据吗?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelDelete"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getSuppliers,
  addSupplier,
  deleteSupplier,
} from '@/api/supplier'
import {
  mdiMagnify,
  mdiDelete,
  mdiArrowRightCircle,
  mdiCheckCircle,
  mdiCloseCircle,
} from '@mdi/js'
import { getAreas, getCities, getProvinces } from '@/api/city'

export default {
  name: 'Suppliers',
  data() {
    return {
      desserts: [],
      totalDesserts: 0,
      createDialog: false,
      deleteDialog: false,
      rowId: null,
      row: {
        name: null,
        province: null,
        city: null,
        county: null,
        address: '',
        is_active: true,
        username: '',
        mobile: '',
        password: '',
      },
      supplierStatus: [
        {
          text: '启用',
          value: true,
        },
        {
          text: '禁用',
          value: false,
        },
      ],
      provincesList: [],
      provinceLoading: false,
      citiesList: [],
      cityLoading: false,
      areasList: [],
      areaLoading: false,
      loading: true,
      rules: {
        required: value => !!value || '必填.',
        counter: value => (value && value.length <= 20) || '最大20个字符',
        mobile: value => /^1\d{10}$/.test(value) || '无效的手机号',
        password: value => /^(?![^a-zA-Z]+$)(?!\D+$){8,16}/.test(value) || '密码应为8-16位数字字母组合.',
      },
      headers: [
        { text: '供应商名称', value: 'name' },
        { text: '联系人', value: 'username' },
        { text: '手机号', value: 'mobile' },
        { text: '供应产品数', value: 'goods_count' },
        { text: '是否激活', value: 'is_active' },
        { text: '创建时间', value: 'created_at' },
        { text: '更新时间', value: 'updated_at' },
        { text: '操作', align: 'center', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiDelete,
        mdiArrowRightCircle,
        mdiCheckCircle,
        mdiCloseCircle,
      },
      options: {
        page: 1,
        pageSize: 10,
        search: '',
      },
    }
  },

  created() {
    this.getDesserts()
  },

  methods: {
    async getProvinces() {
      this.provinceLoading = true
      const { data } = await getProvinces()
      this.provincesList = data
      this.provinceLoading = false
    },
    changeProvinces() {
      this.row.city = null
      this.row.county = null
    },
    async getCities() {
      if (!this.row.province) {
        return
      }
      this.cityLoading = true
      const { data } = await getCities({ province_code: this.row.province })
      this.citiesList = data
      this.cityLoading = false
    },
    async getAreas() {
      if (!this.row.city) {
        return
      }
      this.areaLoading = true
      const { data } = await getAreas({ city_code: this.row.city })
      this.areasList = data
      this.areaLoading = false
    },
    getDesserts() {
      this.loading = true
      getSuppliers(this.options).then(response => {
        this.desserts = response.data.results
        this.totalDesserts = response.data.count
        this.loading = false
      })
    },
    detailBtnClick(item) {
      this.$router.push({ name: 'supplierDetail', params: { id: item.id } })
    },
    deleteBtnClick(item) {
      this.rowId = item.id
      this.deleteDialog = true
    },
    cancelDelete() {
      this.rowId = null
      this.deleteDialog = false
    },

    async deleteItem() {
      await deleteSupplier(this.rowId)
      this.rowId = null
      this.deleteDialog = false
      this.$toast.success('删除成功')
      this.options.page = 1
      this.getDesserts()
    },
    async newItemOpenDialog() {
      this.row.is_active = true
      this.createDialog = true
    },
    async createDialogSubmit() {
      if (!this.$refs.supplierFormRef.validate()) return
      const result = await addSupplier(this.row)
      this.$toast.success('创建成功')
      await this.$router.push({
        name: 'supplierDetail',
        params: { id: result.data.id },
      })
    },

    cancelDialog() {
      this.$refs.supplierFormRef.reset()
      this.createDialog = false
    },
  },
}
</script>
