import request from '@/utils/request'

export function getSuppliers(params) {
  return request.get('/api/v1/backend/suppliers', { params })
}

export function addSupplier(data) {
  return request.post('/api/v1/backend/suppliers', data)
}

export function getSupplier(id) {
  return request.get(`/api/v1/backend/suppliers/${id}`)
}

export function updateSupplier(id, data) {
  return request.put(`/api/v1/backend/suppliers/${id}`, data)
}

export function getSupplierItems(id, params) {
  return request.get(`/api/v1/backend/suppliers/${id}/items`, { params })
}

export function updateSupplierItems(id, data) {
  return request.post(`/api/v1/backend/suppliers/${id}/items`, data)
}

export function deleteSupplier(id) {
  return request.delete(`/api/v1/backend/suppliers/${id}`)
}
